.TC-Text {
  height: 60vh;
  overflow-y: auto;
}
.TC-Text h2 {
  font-size: 18px;
  margin: 10px 0px;
}
.TC-Text h3 {
  font-size: 16px;
  margin: 10px 0px;
}
.TC-Text h4 {
  font-size: 14px;
  margin: 10px 0px;
}

.TC-Text h5 {
  font-size: 14px;
  margin: 10px 0px;
}

.TC-Text {
  font-size: 12px;
}
.TermsCondtnsBox ul li {
  font-size: 12px;
}
.TermsCondtnsBox p {
  font-size: 12px;
}

ol,
ul {
  margin: 10px 0px;
  padding: 0px;
}
.TermsCondtnsBox a {
  color: #ed540f;
}
