@font-face {
  font-family: "preosperegular";
  src: url("../fonts/preospe-webfont.woff2") format("woff2"),
    url("../fonts/preospe-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.top-space-1 {
  margin-top: 130px;
}
.slider-home-1 {
  max-width: 1170px;
  margin: 0 auto;
  width: 94%;
}

.slider-img-2 {
  width: 90%;
  box-shadow: 3px 3px 0px #fa4e0129;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  margin: 10px auto;
}
.slider-img-2 img {
  width: auto;
  height: 50px;
  margin: 0 auto;
  border-radius: 0px;
}
.slick-prev:before {
  content: url(../images/left-arrow.svg) !important;
}
.slick-next:before {
  content: url(../images/right-arrow.svg) !important;
}
.slider-img-2 h4 {
  padding: 20px 0px 0px 0px;
  color: #000;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}
.slider-img-2 a {
  text-decoration: none;
  color: #000;
}

.top-categories-sec-1 {
  padding-top: 40px;
}

.top-categories-sec-1 h2 {
  font-size: 25px;
  /* font-family: "Montserrat"; */
  font-family: "Asap Condensed", sans-serif;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: 500;
  padding-left: 2%;
}

.slider-img-2 h4 {
  font-size: 14px;
}
.ExtraOffers {
}
