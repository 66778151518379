.Subscriptions-bg-1 {
  padding: 30px 0px;
}
.Subscriptions-bg-1 h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 0px;
  padding: 10px 0px 0px 0px;
  text-transform: uppercase;
}
.Subscriptions-bg-1 h6 {
  font-weight: 300;
  font-size: 14px;
  margin: 14px 0px 20px 0px;
}
.Refer-Earn b {
  border: 1px dashed #5140d5;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  border-radius: 5px;
  color: #5140d5;
}
.Subscriptions-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0px 80px 0px;
}
.Subscriptions-box {
  width: 22%;
  position: relative;
  padding: 20px;
  border: 1px solid #5140d5;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 25px #00000029;
}
.Subscriptions-box ul {
  margin: 20px 0px;
  padding: 0px;
  list-style: none;
  display: block;
}
.Subscriptions-box ul li {
  font-size: 14px;
  margin: 0px;
  padding: 0px 0px 10px 20px;
  background-image: url(../images/check-icon.png);
  background-repeat: no-repeat;
  background-position: left top 8px;
  text-align: left;
}
.Subscriptions-box h3 {
  margin: 10px 0px;
  text-transform: uppercase;
  font-size: 30px;
  font-style: normal;
}
.modal-1 {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

@media screen and (max-width: 1024px) {
  .Subscriptions-wrap {
    flex-wrap: wrap;
  }
  .Subscriptions-box {
    width: 45%;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 830px) {
}
@media screen and (max-width: 600px) {
  .Subscriptions-box {
    width: 100%;
    margin-bottom: 25px;
  }
}
