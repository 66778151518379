.DashboardSlider-bg-1 {
  padding: 30px 0px 60px 0px;
}

.Winnings-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 25px 0px 40px 0px;
  flex-wrap: wrap;
}
.border-1 img {
  width: 100%;
  height: auto;
}
.DashboardSlider-bg-1 h3 {
  margin: 10px;
  padding: 0px;
  text-align: left;
  font-size: 16px;
}
.DashboardSlider-bg-1 h6 {
  margin: 10px 0px;
  font-size: 14px;
  text-align: left;
  color: #5240d5;
  font-weight: 400;
}
.DashboardSlider-bg-1 p {
  margin: 10px;
  font-size: 14px;
  text-align: left;
}

.DashboardSlider .slick-next:before {
  /* content: url(../images/right-arrow.png) !important; */
}
.DashboardSlider .slick-prev:before {
  /* content: url(../images/left-arrow.png) !important; */
}
.border-1 {
  border: 1px solid #ccc;
  width: 84% !important;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  height: 100%;
  border-radius: 10px;
}
.DashboardSlider .slick-slider {
  text-align: center;
}
.TermsConditionsBox {
  padding: 5px 0px 10px 0px;
}
.TermsConditionsBox input {
  vertical-align: middle;
  margin-right: 5px;
}

.DashboardSlider-bg-1 ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.DashboardSlider-bg-1 ul li {
  margin: 0px 0px 10px 14px;
  font-size: 15px;
  padding: 0px;
  list-style: circle;
}

.paragraph-1 p {
  margin: 0px 0px 15px 0px;
  font-size: 15px;
  padding: 0px;
}
.form-group {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5240d5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #5240d5;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.paragraph-1 img {
  max-width: 100%;
  height: auto;
}

.video-wrap-main {
  width: 100%;
  display: flex;
}
.video-wrap-1 {
  width: 60%;
  padding-right: 15px;
}
.video-wrap-2 {
  flex: 1;
  background-color: #ededed;
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.video-wrap-2 iframe {
  border: 0px;
  max-height: 250px;
}
.video-wrap-2 h4 {
  margin: 0px 0px 14px 0px;
  padding: 0px;
  color: #5240d5;
  font-size: 16px;
}
@media screen and (max-width: 830px) {
}

@media screen and (max-width: 767px) {
  .video-wrap-main {
    flex-wrap: wrap;
  }
  .video-wrap-1 {
    width: 100%;
    padding-right: 0px;
  }
  .video-wrap-2 {
    width: 100%;
    flex: inherit;
    margin-top: 14px;
  }
}
@media screen and (max-width: 600px) {
}
