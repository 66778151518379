.top-space-1 {
  margin-top: 130px;
}
.slider-home-1 {
  max-width: 1170px;
  margin: 0 auto;
}

.slider-img-1 {
  width: 90%;
  margin: 0 auto;
}
.slider-img-1 img {
  width: 100%;
  border-radius: 15px;
}
.slick-prev:before {
  content: url(../images/left-arrow.svg) !important;
}
.slick-next:before {
  content: url(../images/right-arrow.svg) !important;
}
