.header {
  position: absolute;
  width: 100%;
  top: 40px;
}
.nav-wrapper {
  display: flex;
  width: 100%;
}
.nav-1 {
  width: 250px;
}
.nav-1 img {
  width: 94%;
  height: auto;
}
.nav-2 {
  flex: 1;
  text-align: right;
  position: relative;
}
nav {
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Rubik", sans-serif;
}
nav ul {
  position: relative;
  zoom: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
nav ul li {
  display: inline-block;
  margin-left: 20px;
}
.MyAccountNav {
  background-color: #fff;
  padding: 10px;
  width: 120px;
  position: absolute;
  right: 0px;
  z-index: 9999;
  border-radius: 10px;
  top: 46px;
  box-shadow: 0px 0px 25px #00000029;
}
.MyAccountNav ul {
  margin: 0px;
  padding: 0px;
}
.MyAccountNav ul li {
  margin: 0px;
  padding: 5px 0px;
  display: block;
  list-style: none;
  text-align: center;
}
.MyAccountNav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  display: block;
  text-transform: uppercase;
}
.MyAccountNav ul li a:hover {
  color: #5140d5;
}
@media screen and (max-width: 830px) {
  .nav-1 {
    width: 140px;
  }
}
