.footer-bg {
  /*background-image: url(../images/footer.png);*/
  /*position: fixed;bottom: 0px; */
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 20px 0px 10px 0px;
  text-align: center;
  font-size: 14px;
  background-color: #37356d;
  color: #fff;
}
.footer-wrap {
  display: flex;
  width: 100%;
}
.footer-1 {
  flex: 1;
  text-align: left;
}
.footer-2 {
  text-align: right;
}

.footerlinks ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.footerlinks ul li {
  margin: 0px 20px;
  padding: 0px;
  list-style: none;
  display: inline-block;
}
.footerlinks ul li a {
  color: #fff;
  text-decoration: none;
}

.rotate {
  transition: transform 0.3s ease;
}

.rotated {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .footer-wrap {
    flex-wrap: wrap;
  }
  .footer-1 {
    flex: inherit;
    text-align: center;
    width: 100%;
    margin-bottom: 14px;
  }
  .footer-2 {
    flex: inherit;
    text-align: center;
    width: 100%;
  }
}
