.inner-banner-bg {
  background-size: cover;
  width: 100%;
  background-position: center bottom;
  /* height: 180px; */
  height: 100px;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.inner-banner-1 {
  padding: auto;
}
@media screen and (max-width: 830px) {
  .inner-banner-bg {
    height: 66px;
  }
}
