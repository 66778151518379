.subscription-main-container{
    background-color: #fff;
    width: auto;
    padding: 10px 0px 20px 10px;
    border-radius: 10px;
    border: 1px soild #fff;
}
table {
  border-collapse: collapse;
  width: 100%;}
  
td, th {border: none;text-align: left;padding: 10px;border-bottom: 1px solid #DEE2E6;}
.table{border-radius: 20px;background: #FFF;padding: 13px;}
th:first-child, td:first-child{text-align: left;}
th, td{text-align: center;}
.td-1{display: flex;align-items: center;}
.td-1 img{padding-left: 10px;}
.buttons td{border-bottom: none;}
.btn-club{
  /* border: 1px soild #000; */
  background-color: transparent;
  border-radius: 25px;
  outline: none;
  padding: 5px 18px 5px 18px;
  font-size: 13px;
  border: 5px soild #000;
}
.btn-citizen{
  background-color: #fff;
  border-radius: 25px;
  
  padding: 5px 18px 5px 18px;
  font-size: 13px;
  border: 1px soild #6AA6FF;
  color: #6AA6FF;
}
.btn-cele{
  background-color: #fff;
  border-radius: 25px;
  
  padding: 5px 18px 5px 18px;
  font-size: 13px;
  border: 1px soild #AB65D0;
  color: #AB65D0;
}
.btn-champ{
  background-color: #fff;
  border-radius: 25px;
  padding: 5px 18px 5px 18px;
  font-size: 13px;
  border: 1px soild #E77502;
  color: #E77502;
}

.buttons td {
  text-align: center;
  padding: 10px;
}

.buttons button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttons .btn-clubb {
  background-color: #4caf50; 
  color: white;
}

.buttons .btn-clubb:hover {
  background-color: #45a049; 
}

.buttons .btn-citizen {
  background-color: #6aa6ff; 
  color: white;
}

.buttons .btn-citizen:hover {
  background-color: #5a94e0; 
}

.buttons .btn-champion {
  background-color: #e77502; 
  color: white;
}

.buttons .btn-champion:hover {
  background-color: #c66502; 
}

.buttons .btn-celebrity {
  background-color: #ab65d0; 
  color: white;
}

.buttons .btn-celebrity:hover {
  background-color: #954fbb; 
}

.card{
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 10px;
}
.card-header{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .subscription-main-container {
    padding: 10px;
  }

  table {
    font-size: 14px;
  }

  th, td {
    padding: 8px;
  }

  .buttons button {
    padding: 8px 15px;
    font-size: 12px;
  }
  .card-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

@media (max-width: 480px) {
  .subscription-main-container {
    padding: 5px;
  }

  table {
    font-size: 12px;
  }

  th, td {
    padding: 6px;
  }

  .td-1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons button {
    padding: 5px 10px;
    font-size: 10px;
  }
}

.table > :not(caption) > * > * {
  border-bottom-width: 0 !important;
}

.subscription-main-container{
  background-color: #fff;
  width: auto;
  padding: 10px 0px 20px 10px;
  border-radius: 10px;
  border: 1px soild #fff;
}
table {
border-collapse: collapse;
width: 100%;}

td, th {border: none;text-align: left;padding: 10px;border-bottom: 1px solid #DEE2E6;}
.table{border-radius: 20px;background: #FFF;padding: 13px;}
th:first-child, td:first-child{text-align: left;}
th, td{text-align: center;}
.td-1{display: flex;align-items: center;}
.td-1 img{padding-left: 10px;}
.buttons td{border-bottom: none;}
.btn-club{
/* border: 1px soild #000; */
background-color: transparent;
border-radius: 25px;
outline: none;
padding: 5px 18px 5px 18px;
font-size: 13px;
border: 5px soild #000;
}
.btn-citizen{
background-color: #fff;
border-radius: 25px;

padding: 5px 18px 5px 18px;
font-size: 13px;
border: 1px soild #6AA6FF;
color: #6AA6FF;
}
.btn-cele{
background-color: #fff;
border-radius: 25px;

padding: 5px 18px 5px 18px;
font-size: 13px;
border: 1px soild #AB65D0;
color: #AB65D0;
}
.btn-champ{
background-color: #fff;
border-radius: 25px;
padding: 5px 18px 5px 18px;
font-size: 13px;
border: 1px soild #E77502;
color: #E77502;
}

.buttons td {
text-align: center;
padding: 10px;
}

.buttons button {
padding: 10px 20px;
font-size: 14px;
font-weight: 600;
border: none;
border-radius: 25px;
cursor: pointer;
transition: all 0.3s ease;
}

.buttons .btn-clubb {
background-color: #4caf50; 
color: white;
}

.buttons .btn-clubb:hover {
background-color: #45a049; 
}

.buttons .btn-citizen {
background-color: #6aa6ff; 
color: white;
}

.buttons .btn-citizen:hover {
background-color: #5a94e0; 
}

.buttons .btn-champion {
background-color: #e77502; 
color: white;
}

.buttons .btn-champion:hover {
background-color: #c66502; 
}

.buttons .btn-celebrity {
background-color: #ab65d0; 
color: white;
}

.buttons .btn-celebrity:hover {
background-color: #954fbb; 
}

.card{
/* border-top-left-radius: 10px;
border-top-right-radius: 10px; */
border-radius: 10px;
}
.card-header{
border-top-left-radius: 10px;
border-top-right-radius: 10px;
}
/* Responsive Styles */
@media (max-width: 768px) {
.subscription-main-container {
  padding: 10px;
}

table {
  font-size: 14px;
}

th, td {
  padding: 8px;
}

.buttons button {
  padding: 8px 15px;
  font-size: 12px;
}
.card-header{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popup-content {
  width: 95%; /* Slightly smaller width on very small screens */
  padding: 15px; /* Adjust padding for small screens */
}

.close-popup {
  font-size: 20px; /* Adjust close button size */
}
}

@media (max-width: 480px) {
.subscription-main-container {
  padding: 5px;
}

table {
  font-size: 12px;
}

th, td {
  padding: 6px;
}

.td-1 {
  flex-direction: column;
  align-items: flex-start;
}

.buttons button {
  padding: 5px 10px;
  font-size: 10px;
}
.popup-content {
  width: 95%; /* Slightly smaller width on very small screens */
  padding: 15px; /* Adjust padding for small screens */
}

.close-popup {
  font-size: 20px; /* Adjust close button size */
}
}


.popup-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
display: flex;
justify-content: center;
align-items: center;
}

.popup-content {
position: relative;
background: white;
padding: 20px;
width: 100%; /* Adjust as needed */
text-align: center; /* Centers the text */
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.popup-content .p{
font-size: 3px;
}

.close-popup {
position: absolute;
top: -8px;
right: -05px;
background: none;
border: none;
font-size: 20px;
cursor: pointer;
}

.close-popup:hover {
color: red;
}


.selected-features li {
font-size: 16px;
}  

.table > :not(caption) > * > * {
border-bottom-width: 0 !important;
}
