@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,300;1,500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed&display=swap");

/*font-family: 'Asap Condensed', sans-serif;*/
h1,
h2,
h3 {
  font-family: "Asap Condensed", sans-serif;
}
h1 {
  font-size: 38px;
  text-transform: uppercase;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
.wrapper {
  max-width: 1170px;
  margin: 0 auto;
  width: 94%;
}
.button-1 {
  background-color: #fff;
  padding: 5px 10px;
  color: #5140d5;
  font-family: "Nunito", sans-serif;
}
.clipping-container {
  position: relative;
  border: 1px solid #aaa;
  margin-bottom: 12px;
  padding: 12px;
  width: 250px;
  height: 80px;
  overflow: hidden;
}
.modal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 12px;
  position: absolute;
  width: 1170px;
  top: 20px;
  left: calc(50% - 555px);
  bottom: 70px;
  height: 80vh;
}
hr {
  height: 1px;
  background-color: #707070;
  width: 100%;
  border: 0px;
  margin: 15px 0px;
}
.btn-1 {
  font-family: "Asap Condensed", sans-serif;
  border: 1px solid #707070;
  font-size: 18px;
  padding: 5px 10px;
  background-color: #f7f7f7;
  color: #000000;
  display: inline-block;
  border-radius: 5px;
}
.btn-2 {
  font-family: "Asap Condensed", sans-serif;
  border: 1px solid #5140d5;
  font-size: 18px;
  padding: 5px 10px;
  background-color: #5140d5;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
}

.btn-3 {
  font-family: "Asap Condensed", sans-serif;
  border: 0px;
  font-size: 18px;
  padding: 5px 10px;
  background-color: #fff;
  color: #282625;
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  border: 1px solid #fa4e01;
}

.btn-1 a {
  border: 0px;
  font-size: 18px;
  background-color: #f7f7f7;
  color: #000000;
  text-decoration: none;
  border-radius: 5px;
}
.btn-2 a {
  border: 0px;
  font-size: 18px;
  background-color: #5140d5;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.min-height-1 {
  min-height: 90vh;
}

body {
  background-color: #fff;
  background-image: url(./components/images/new-bg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
  
}

@media screen and (max-width: 767px) {
  .slick-next {
    right: 0px !important;
  }
  .slick-prev {
    left: -5px !important;
  }
}
