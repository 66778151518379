/* General Styles */
.p-d-container {
    background: linear-gradient(to right, white, #f0f0f0);
    width: 100%;
    display: flex;
    flex-direction: row; /* Default layout */
    border-radius: 20px;
    border: 1px solid #ffff;
    padding: 15px;
    margin: 25px auto;
}

.product-details-section-1 {
    background-color: #fff;
    height: auto;
    display: flex; /* Use flexbox to center image */
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
    padding: 40px;
    margin: 17px 0px 17px 15px;
    border: 1px solid #FA4F01;
    border-radius: 5px;
    
}

.product-details-section-1 img {
    max-width: 100%; /* Ensure image is responsive */
    height: auto;
}

.product-details-section-2 {
    flex: 1;
    margin: auto;
    padding-left: 20px;
    margin: 7px 0px 17px auto;
}

.s-p-heading {
    font-family: "Asap Condensed";
    font-size: 28px;
    font-weight: 300;
    line-height: 41.26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.s-p-details-tockens {
    background-color: #FFD1BD;
    border: #FFD1BD;
    width: 18%;
    color: #FA4F01;
    border-radius: 5px;
    text-align: left;
    padding: 4px;
    padding-left: 12px;
}

.s-p-charges {
    font-family: "Poppins";
    font-weight: 450;
}

.s-p-span {
    color: #FA4F01;
}

.product-details-description {
    /* width: 85%;
    overflow-y: scroll;
    height: 150px; */
    width: 85%;
    max-width: 100%; /* Ensure it doesn't exceed container width */
    overflow-y: auto; /* Use 'auto' to show scroll only when needed */
    /* height: 150px; Fixed height */
    height: auto;
    max-height: 150px;
    padding-right: 14px;
    /* Add padding for better readability */
    box-sizing: border-box; /* Include padding in the width calculation */
    
}


.s-p-button-container {
    display: flex;
    justify-content: space-between;
}

.back-btn {
    background-color: #fff;
    border: 1px solid #FA4F01;
    color: #FA4F01;
    padding: 3px 23px 3px 23px;
    border-radius: 5px;
}

/* Responsive Styles */

/* For tablets (768px and below) */
@media (max-width: 768px) {
    .p-d-container {
        flex-direction: column; /* Stack sections vertically */
        padding: 10px;
    }

    .product-details-section-1 {
        margin: 10px auto; /* Center section horizontally */
        padding: 20px;
        width: 100%;
    }

    .s-p-heading {
        font-size: 24px; /* Adjust font size */
    }

    .s-p-details-tockens {
        width: 20%; /* Adjust width for smaller screens */
    }

    .product-details-description {
        width: 100%; /* Full width for smaller screens */
    }
    .product-details-section-2{
       
        padding: 2px 0px 13px auto;
        padding-left: 5px;
    }
}

/* For mobile devices (480px and below) */
@media (max-width: 480px) {
    .p-d-container {
        padding: 8px;
    }

    .product-details-section-1 {
        margin: 8px auto; /* Center section horizontally */
        padding: 15px;
    }

    .product-details-section-1 img {
        max-width: 80%; /* Reduce image size for small screens */
    }

    .s-p-heading {
        font-size: 20px; /* Further adjust font size */
    }

    .s-p-details-tockens {
        width: 40%; /* Reduce width further */
        font-size: 12px; /* Adjust font size */
        padding: 5px;
    }

    .s-p-button-container {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px; /* Add spacing between buttons */
    }

    .back-btn {
        padding: 8px 15px; /* Adjust button padding */
        font-size: 14px;
    }
    .product-details-section-2{
        padding-left: 5px;
        padding: 2px 0px 13px auto;
    }

}
