.Contact-wrap {
  padding: 30px 0px;
}
.Contact-wrap {
  width: 96%;
  max-width: 1170px;
  margin: 0 auto;
}
.Contact-form-wrap {
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin: 0px 0px 0px 0px;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 5px;
}
.reg-2 {
  width: 30%;
  padding: 0px 0px 20px 0px;
}

.reg-1 {
  width: 45%;
  padding: 0px 0px 20px 0px;
}
.reg-1 b {
  font-weight: 300;
  font-size: 14px;
  margin: 0px 0px 6px 0px;
  display: block;
  color: #333333;
}
.w-100 {
  width: 100% !important;
}
.Contact-button {
  background-color: #5140d5;
  padding: 5px 20px;
  color: #fff;
  font-family: "Nunito", sans-serif;
  border: 0px;
  margin: 20px 0px 0px 0px;
  border-radius: 5px;
  text-transform: uppercase;
}

.contact-address-wrap {
  display: flex;
  width: 100%;
  margin: 30px 0px;
  flex-wrap: wrap;
}
.contact-address-1 {
  width: 30%;
}
.contact-address-1 h4 {
  font-size: 20px;
  margin: 0px 0px 20px 0px;
  text-decoration: none;
}
.contact-address-2 {  
  flex: 1;
}

/* changes done in 4-12-24 */
.shopping-product-details-items-2 .h4{
  font-family: Asap Condensed;
  text-decoration: none;
  font-size: 36px;
}

.wrapper-sec-1{display: flex; justify-content: space-between;}
.balance-tockens-lsit{display: flex;}
.tbalnce{font-size: 22px;color: #FA4F01;font-weight: 400 ; text-transform: capitalize; margin-left: 15px;align-self: center;}
.tbalnce h2 span {color: #000;}
.tockens-box {background-color: #fff; padding: 5px 20px; color: #000; font-size: 20px; width: 50%;}
.checkout-wrap {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 30px 0px;
  flex-wrap: wrap;
}
.shopping-checkout-1 {
  width: 60%;
  background: linear-gradient(to right, white, #f0f0f0);
  border-radius: 20px;
  height: 50%;
  margin: 0px 20px 0px 0px;
}
.checkout-1 h4 {
  font-size: 30;
  margin: 0px 0px 20px 0px;
  text-decoration: none;
}
.shopping-checkout-2 {
  flex: 1;
  background: linear-gradient(to right, white, #f0f0f0);
  border-radius: 20px;
  padding: 20px;
}

.checkout-items-wrap {
  display: flex;
  width: 100%;
  margin: 30px 0px;
  flex-wrap: wrap;
}
.checkout-items-1 {
  width: 30%;
}
.checkout-items-2 {
  flex: 1;
  margin: auto;
}
.checkout-items-wrap h4 {
  font-size: 20px;
  color: #000;
  margin: 0px 0px 20px 0px;
  padding: 0px;
}
.checkout-items-wrap h5 {
  font-size: 20px;
  color: #fa4e01;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  text-decoration: none;
  font-weight: 600;
}
.checkout-items-wrap p {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}
.checkout-items-1 img {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: auto;
  padding: 15px 20px;
}

.checkout-form-wrap {
  display: flex;
  /* width: 100%; */
  /* max-width: 800px; */
  /* padding: 20px; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin: 0px 0px 0px 0px;
  /* box-shadow: 0px 0px 25px #00000029; */
  border-radius: 5px;
  /* background-color: #fff; */
}

.your-order-1-main ul {
  list-style: none;
  display: flex;
  flex-grow: 1;
}

/* .search-i-1{
  display: flex;
  justify-content
} */
.search-btns{
  display: flex;
  /* justify-content: space-around; */
}
.button-clear{
  background-color: #fff;
  padding: 10px 23px;
  border-radius: 5px;
  /* background-color: #fa4e01; */
  margin-left: 20px;
  border: 0px;
  color: #000;
  height: -moz-fit-content;
  height: fit-content;
}


@media screen and (max-width: 830px) {
  .reg-1 {
    width: 100%;
    padding: 0px 0px 10px 0px;
  }
  .reg-2 {
    width: 100%;
    padding: 0px 0px 10px 0px;
  }
}

@media screen and (max-width: 600px) {
  .checkout-1 {
    width: 100%;
    background: linear-gradient(to right, white, #fcfcfc);
  }
  .checkout-2 {
    width: 100%;
  }
  .contact-address-1 {
    width: 100%;
  }
  .contact-address-2 {
    width: 100%;
    margin-top: 10px;
  }
  .checkout-items-wrap h4 {
    font-size: 16px;
    margin: 0px 0px 10px 0px;
  }
  .checkout-items-wrap h5 {
    font-size: 15px;
    margin: 0px 0px 5px 0px;
  }
  .checkout-items-wrap p {
    font-size: 12px;
    margin: 0px 0px 5px 0px;
  }
  .checkout-wrap{
    display: contents;
  }
  .shopping-checkout-1{
    width: 100%;
  }
  .checkout-items-1{
    align-self: center;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .checkout-items-2 {
    padding: 5px 0px 5px 0px;
  }
  .checkout-items-2 h4{
    font-size: 10px;
  }
  .payment-items-wrap{
    display: contents;
  }
  .checkout-items-wrap{
    display: contents;
  }

}

.your-order-main {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  

  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  /* -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29); */

  flex-wrap: wrap;
}
.list-2{
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  /* border-radius: 5px; */
  

  /* box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29); */
  /* -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29); */

  flex-wrap: wrap;
}
.orders-main-list{padding: 10px 20px;}
.your-order-main-list {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #FFF6F2;

  margin-bottom: 20px;

  /* box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.2); */
  /* -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29); */

  flex-wrap: wrap;
}

.your-order-main-list-credited{
  display: flex;
  justify-content: center;
  width: 100%;
  padding:10px 30px 10px 30px;
  margin-bottom: 10px;
  /* margin-left: 20px; */
  border-radius: 5px;
  background: #FFF6F2;
  -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
}
.your-order-main-list-debited{
  display: flex;
  justify-content: center;
  width: 100%;
  padding:10px 30px 10px 30px;
  margin-bottom: 10px;
  /* margin-left: 20px; */
  border-radius: 5px;
  /* background: #FFF6F2; */
  -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
}
.error-message{color: red;}
.your-order-1-main {
  border-radius: 10px 10px 0px 0px;
border: 1px solid #D0CBC8;
background: #FD8F5D;
  display: flex;
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  color: #ffffff;
}
.Loading{
  text-align: center;
}
/* .your-order-2-main {
  background-color: #000;
  display: flex;
  width: 100%;
  padding: 10px;
} */

.your-order-1-1 {
  flex: 1;
}
.your-order-1-1-date {
  flex: 1;
  padding-left: 20px;
}
.main-order-block{background: #ffffff;border-radius: 10px 10px 0px 0px;}
.date{color: #BC00A9}
.order{color: #F00;padding-left: 15px;}
.tocken{width: 100%;}
.withdraw{width: 100%;}
.debited{border-radius: 20px;
  background: rgba(0, 116, 199, 0.10);padding: 10px;color: #0074C7;}
.credited{border-radius: 20px;
  background: rgba(52, 199, 0, 0.10);color: #34C700;padding: 10px;}
.your-order-2-main {
  display: flex;
  width: 100%;
  padding: 20px;
  padding-left: 38px;
  /* background: #fff; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.your-order-2-1-main {
  display: flex;
  width: 100%;
  padding: 20px;
  padding-left: 38px;
  background: #fff; 
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.your-order-2-1 {
  width: 100px;
}
.your-order-2-1 img {
  width: 100%;
  height: auto;
}
.your-order-2-3 {
  width: 250px;
}
.your-order-2-3 h5 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
}
.your-order-2-3 p {
  font-size: 13px;
  list-style: 22px;
}
.your-order-2-2 {
  flex: 1;
  padding-left: 20px;
  margin: auto;
}
.your-order-2-main h4 {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.search-i-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.search-i-1 {
  width: 30%;

  /* display: flex;
  flex-wrap: wrap; */
  align-self: end;
}
.search-input-1 {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 7px;
}
.button-search {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fa4e01;
  border: 0px;
  color: #fff;
  height: fit-content;
}

.search-i-1 h5 {
  text-decoration: none;
  color: #333333;
}

@media screen and (max-width: 600px) {
  .your-order-2-main {
    padding: 10px 10px;
    flex-wrap: wrap;align-items: center;
  }
  .search-i-main {
    padding: 10px 0px;
    flex-wrap: wrap;
  }
  .your-order-2-3 {
    width: 100%;
    padding: 6px;
    background: #ebebeb;
    margin: 10px 0px 0px 0px;
    border-radius: 5px;
  }
  .your-order-2-1 {
    width: 40px;
  }
  .your-order-2-2 p {
    display: none;
  }
  .your-order-1-main {
    padding: 10px;
    font-size: 10px;
  }
  .your-order-1-main ul  {
    list-style: none;
    display: flex;
  }
  
  .Myaccount-bg-1 h4 {
    font-size: 12px;
  }

  .Myaccount-bg-1 h5 {
    font-size: 12px;
  }

  .your-order-2-3 p {
    font-size: 10px;
    line-height: 14px;
  }
  .search-i-1 {
    width: 100%;
    margin-bottom: 10px;
  }
  .button-search {
    padding: 6px 10px;
    font-size: 12px;
  }
  .button-clear {
    padding: 5.5px 16px;
    font-size: 12px;
  }
  .search-input-1 {
    width: 100%;
    padding: 6px 6px;

    font-size: 12px;
  }
  .your-order-main-list-credited, .your-order-main-list-debited{padding:10px}
  .order, .date, .credited, .debited{font-size:12px}
  .orders-main-list {
    padding: 10px;
}
.product-btn-card button{
  font-size: 12px;
}

.wrapper-sec-1{
  display: contents;
}
.balance-tockens-lsit{
  display: flex;
}
.tockens-box {
  background-color: #fff;
  padding: 2px 16px;
  color: #000;
  font-size: 16px;
  width: 50%;
}
.tbalnce{font-size: 14px;}

}


.pagination {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.pagination button {
  background-color: #4CAF50; /* Green background */
  border: none;
  color: white; /* White text */
  padding: 10px 20px; /* Larger padding for better clickability */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 5px; /* Reduced margin for tighter spacing */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  min-width: 100px; /* Minimum width for buttons */
}

.pagination button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.pagination button:disabled {
  background-color: #ccc; /* Gray background when disabled */
  color: #666; /* Lighter text */
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  margin: 0 10px;
  color: #333; /* Darker text for better visibility */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .pagination button {
    padding: 8px 12px; /* Smaller padding for medium screens */
    font-size: 14px; /* Smaller font size */
    min-width: 80px; /* Smaller minimum width */
  }

  .pagination span {
    font-size: 14px; /* Smaller font size for medium screens */
    margin: 0 5px; /* Reduced margin for medium screens */
  }
}

@media screen and (max-width: 480px) {
  .pagination {
    justify-content: center; /* Center pagination controls */
  }

  .pagination button {
    padding: 6px 10px; /* Further reduce padding for small screens */
    font-size: 12px; /* Smaller font size */
    min-width: 70px; /* Further reduce minimum width */
    margin: 2px; /* Smaller margin for tighter spacing */
  }

  .pagination span {
    font-size: 12px; /* Smaller font size for small screens */
    margin: 0 5px; /* Reduced margin for better spacing */
  }
  .balance-tockens-lsit{
    flex-wrap: wrap;
  }
}

/* shopping product-details -css */
.product-details-items-wrap{
  /* background-color: #B4B4B4; */
  background: linear-gradient(to right, white, #fcfcfc);
  display: flex;


  width: 150%;
  margin: 30px 0px;
  flex-wrap: wrap;
  border-radius: 20px;
}
.product-details-items-1{
  background-color: #fff;
  height: auto;
  align-content: center;
 padding: 40px;margin: 17px 0px 17px 15px;
 border: 1px solid #FA4F01;
border-radius: 5px;}

.shopping-product-details-items-2{  flex: 1;
  margin: auto;padding-left: 20px;
  margin: 17px 0px 17px auto;
}

/* .s-p-details-tockens{
  background-color: #FFD1BD;
  border: #FFD1BD;
  width: 20%;
  max-width: auto;
  min-width: auto;
  color: #FA4F01;
  text-decoration: underline;
  border-radius: 5px;
  text-align: left;
  padding: 4px;
  padding-left: 10px;
} */


/* .product-details-description{
  width: 85%;
} */


@media screen and (max-width: 480px){
  .product-details-items-wrap{
    display: contents;
    width: auto;
    background: linear-gradient(to right, white, #fcfcfc);
  }

}

@media screen and (max-width: 768px) {
  .product-details-items-wrap{
    display: contents;
    width: auto;
    background: linear-gradient(to right, white, #fcfcfc);
  }
  
}

@media screen and (max-width: 600px){
  .product-details-items-wrap{
    display: contents;
    width: auto;
    background: linear-gradient(to right, white, #fcfcfc);
  }

}

.product-check-out-toggle{
  display: flex;
  /* background-color: #fff; */
  width: auto;
}
.product-btn-card{
  background-color: #FFF2EC;
  /* padding: 6px 8px 6px 10px; */
  border-radius: 20px;
}
.product-btn-card button{
  border-radius: 20px;
  outline: none;
  padding: 7px 22px 7px 22px;
  width: auto;
  border: none;
  margin: 0px 1px 0px 1px;
}
.product-tab-button {
  background-color: #FA4F01;
  border: 1px solid #FA4F01;
  color: #fff;
}
.tockesn-note{
  padding: 5px 0px 0px 0px;
  margin-top: 10px;
}

.tockesn-note span{
  color: #FA4F01;;
}

span{
  color: #FA4F01;
}
.currency_value{ color: #FA4F01;};

.tockens-lsit{
  display: flex;
}
.customer-checkout-items-wrap{
  padding: 22px;
}

.customer-checkout-items-wrap h1{
  font-size: 20px;
}
.customer-checkout-items-wrap p{
  display: flex;
  padding-left: 3px;
  margin-bottom: 10px;
}
.cupon-input{
  border: 1px solid #C0C0C0;
  outline: none;
  width: auto;
  border-radius: 10px;
  padding: 8px;
}
.cupon-button-container{
  display: flex;
}
.verify-btn{
  border-radius: 20px;
  margin-left: 20px;
  color: #FA4E01;
border:1px solid  #FA4E01;
background: transparent;
width: 20%;
}

.discounted-container{
  display: flex;
width: 100%;
align-items: center;
padding-top: 5px;
}
.current-price{
  color: #000;
  font-size: 17px;
  text-decoration:line-through;
}
.disocunt-price{
  color: #FA4E01;
  font-size: 22px;
  margin-left: 10px;
}
.discount-off{color: green;margin-left: 10px;}
.discount{color: green;margin-left: 13px;
  display: block !important;
}
.discount img{
  padding-left: 5px;
}

@media screen and (max-width: 600px) {
  .customer-checkout-items-wrap{
    margin-top: 20px;
    width: auto;
  }
  .cupon-button-container{
    display: contents;
  }
  .cupon-input{
    width: 100%;
  }
  .verify-btn{
    border-radius: 10px;
    width: 30%;
   margin: 10px 0px 0px 0px;
  }
  .customer-checkout-items-wrap h1{
    font-size: 18px;
  }
   .customer-checkout-items-wrap p{
   width: auto;
   font-size: 12px;;
  }
  .checkout-items-wrap{
    display: contents;
  }
  .checkout-items-1 img{
    width: 150px;
  }
  .checkout-items-2{
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .shopping-checkout-2 {
    margin-top: 20px;
  }
  .discount{
    
  }
}

/* Container for the entire order */
.order-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Details section at the top */
.order-details {
  margin-bottom: 15px;
}

.order-info {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

/* Content row for image, product, and address */
.order-content {
  display: flex;
  flex-wrap: wrap; /* Responsive layout */
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

/* Product image */
.order-image img {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

/* Product details */
.order-product {
  flex: 1;
  padding: 0 10px;
}

.order-product h4 {
  margin: 0 0 5px;
  font-size: 16px;
  color: #333;
}

.order-product p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

/* Shipping address */
.order-address {
  flex: 1;
  padding-left: 10px;
}

.order-address h5 {
  margin: 0 0 5px;
  font-size: 16px;
  color: #333;
}

.order-address p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .order-content {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .order-image img {
    max-width: 100px;
  }

  .order-product,
  .order-address {
    padding: 5px 0px 5px 0px;
  }
  .order-info{
    font-size: 10px;
  }
  .order-address{
    padding: 15px;
  }
}


.order-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
}
.track-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}
.track-btn:hover {
  background-color: #0056b3;
}
