.Signup-Bg{position: fixed; width: 100%; height: 100vh; background-color: rgba(0,0,0,0.5); z-index: 9999;}
.Signup-wrap{width: 96%; max-width:800px; padding: 0px; background-color: #fff; margin: 0 auto; position: absolute;border-radius: 5px; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.Signup-Header-Text{background-color: #5140D5; padding: 10px 10px;-webkit-border-top-left-radius: 5px;  -webkit-border-top-right-radius: 5px;  -moz-border-radius-topleft: 5px;  -moz-border-radius-topright: 5px;  border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;}
.Signup-Content-wrap{padding: 20px;}
.Signup-Header-Text h2{ color:#fff; font-size: 30px;text-transform: uppercase; margin-bottom: 14px;;}
.Signup-Header-Text h6{ color:#fff; font-size: 16px; font-weight: 400;}
.reg-wrap{display: flex;width: 100%;padding: 20px; flex-wrap:wrap; justify-content: space-between;}
.reg-1{ width: 45%; padding: 0px 0px 10px 0px;}
.w-100{width: 100% !important;}
.reg-wrap b{margin: 0px 0px 5px 0px; font-weight: 300;font-size: 14px;}
.text-f-1{width: 100%;border: 1px solid #707070;padding: 5px 6px;border-radius: 5px; font-size: 14px;;}
.Signup-button{background-color: #5140D5; padding: 5px 10px; color: #fff; font-family: 'Nunito', sans-serif;
border: 0px; min-width: 180px; margin:20px 0px 0px 0px; border-radius: 5px; text-transform: uppercase;}
