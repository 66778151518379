.payment-status-container {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  .payment-status-container.success {
    background-color: #e8f5e9; /* Light green background for success */
    color: #2e7d32; /* Dark green text */
  }
  
  .payment-status-container.failure {
    background-color: #ffebee; /* Light red background for failure */
    color: #c62828; /* Dark red text */
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  button {
    font-size: 1rem;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-home {
    background-color: #1976d2; /* Blue button */
    color: #fff;
  }
  
  .btn-home:hover {
    background-color: #1565c0;
  }
  
  .btn-retry {
    background-color: #d32f2f; /* Red button */
    color: #fff;
  }
  
  .btn-retry:hover {
    background-color: #b71c1c;
  }
  