@font-face {
  font-family: "preosperegular";
  src: url("../fonts/preospe-webfont.woff2") format("woff2"),
    url("../fonts/preospe-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.extra-offer-wrap {
  max-width: 1170px;
  margin: 0 auto;
  width: 94%;
  padding-bottom: 20px;
}
.survey-btn{
  background-color: #fa4e01;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 20px 0px;
  outline: none;
  border: 0;
  width: 20%;
}

.extra-offer-wrap h1 {
  /* font-family: "Montserrat"; */
  font-family: "Asap Condensed", sans-serif;
  font-size: 28px;

  font-weight: 500;
}
.extra-offer-flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.extra-offer-1 {
  width: 30%;
  padding: 40px 20px;
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 100px #0000001a;
  border-radius: 15px;
  text-align: center;
  margin: 20px 11px;
}
.extra-offer-1 img {
  width: auto;
  height: 30px;
  margin: 0 auto;
  border-radius: 0px;
}
.extra-offer-1 h6 {
  background-color: #fa4e01;

  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 25px 0px 0px 0px;
}
.extra-offer-1 h6 a {
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 12px 15px;
  font-weight: 500;
}

.extra-offer-flex-2-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.surevy-p-tag{
  padding-left: 4px;
  color: #FA4F01;
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
}

.extra-offer-2 {
  width: 33%;
  padding: 0px 20px;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: center;
  margin: 10px 0px;
}
.extra-offer-2-img {
  width: auto;
  margin: 0 auto;
  border-radius: 0px;
  background: #ffffff;
  box-shadow: 0px 24px 100px #0000001a;
  padding: 25px 5px;
  border-radius: 15px;
}

.extra-offer-2-img img {
  width: auto;
  max-width: 90%;
  height: 150px;
  margin: 0 auto;
  border-radius: 0px;
}
.extra-offer-2 h5 {
  margin: 20px 0px 5px 0px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.extra-offer-2 button {
  background-color: #fa4e01;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 20px 0px;
  outline: none;
  border: 0;
  width: 100%;
}

.top-space-1 {
  margin-top: 130px;
}



.survey-item {
  width: 45%;
  margin: 20px 0px 0px 0px;
}
.survey-item input {
  vertical-align: middle;
  margin: 0px 10px 0px 0px;
}

.survey_container h4 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin: 0px 0px 0px 0px;
}

.button-orange-1 {
  background-color: #fa4e01;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 0px;
}

.claim-page {
  width: 90%;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.claim-page-bg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 100px #0000001a;
  border-radius: 10px;
  padding: 50px 40px;
}
.claim-page-bg img{width: auto; max-width:90%; height:80px ;;}
.claim-page-header {
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.backhome {
  margin: 20px;
}
.backhome a {
  text-decoration: none;
  color: #000;
}
.claim-page h6 {
  font-size: 12px;
  font-style: italic;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}

.claim-page h4 {
  font-size: 16px;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}

.claim-page h3 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}
.text-field-1 {
  border: 1px solid #ccc;
  padding: 15px 15px;
  font-size: 14px;
  color: #303030;
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
}

.w-80 {
  width: 80% !important;
  box-sizing: border-box;
}

.claim-page-bg img{
  width: auto;
  margin-bottom: 15px;
}

@media screen and (max-width: 830px) {
  .extra-offer-1 {
    width: 45%;
    padding: 30px 20px;
    margin: 15px 11px;
  }
  .top-categories-sec-1 h2 {
    font-size: 18px;
  }
  .extra-offer-flex {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .extra-offer-2 {
    width: 45%;
  }
  .survey-item {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
}
@media screen and (max-width: 600px) {
  .extra-offer-1 {
    width: 100%;
    margin: 10px 0px;
  }
  .extra-offer-2 {
    width: 100%;
  }
  .claim-page-bg img {
    width: 20%;
    height: 30%;
  }
  .surevy-p-tag{
    padding-left: 4px;
    font-size: 12px;
  }
  
}


/* Popup Container */
/* Popup Container */
.custom-popup-container {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above all content */
}

/* Popup Content */
.custom-popup-content {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Responsive width */
  width: 400px; /* Default width */
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
}

/* Popup Buttons */
.popup-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.popup-button-cancel,
.popup-button-ok {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-button-cancel {
  background-color: #f44336; /* Red for Cancel */
  color: #fff;
}

.popup-button-ok {
  background-color: #4caf50; /* Green for OK */
  color: #fff;
}

.popup-button-cancel:hover {
  background-color: #d32f2f;
}

.popup-button-ok:hover {
  background-color: #388e3c;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .custom-popup-content {
    padding: 15px 20px;
    font-size: 14px;
  }

  .popup-button-cancel,
  .popup-button-ok {
    font-size: 12px;
    padding: 8px 15px;
  }
}

.survey-list-container{
  background-color: #fff;
  border-radius: 10px;
  padding: 6px 22px 6px 22px;
}

.survey_container {
  background-color: #FFF9F6;
  padding: 16px 20px;
  border-radius: 5px;
  border: 1px soild #fff9f6;
  border-radius: 11px;
  /* background: #FFF9F6 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 24px 100px #0000001a; */
  margin: 25px 0px;
}

.survey-item-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 401;
  padding: 1px 0px 10px 0px;
}

.radio-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 12px; /* Adjust the gap between options */
  padding: 10px 0px 0px 0px;
}

.radio-options div {
  display: flex;
  align-items: center; /* Align input and label vertically */
}

.radio-options input {
  margin-right: 8px; /* Adds spacing between radio button and label */
}

.text-input{
  width: 100%;border-radius: 5px;
  border : 1px solid #C1C1C1;
  padding: 3px;
  padding: 10px 0px 0px 0px;
}
.file-input{
  padding: 10px 0px 0px 0px;
}
.select-options{
  padding: 10px 0px 0px 0px;
}


/* Media queries for responsiveness */
@media (min-width: 768px) {
  .survey-item-wrap {
    flex-direction: row; /* Return to row layout for larger screens */
    justify-content: space-between;
  }

  .radio-options {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    gap: 12px;
  }

  .text-input,
  .file-input,
  .select-options {
    padding: 10px;
    width: 100%;
  }
}

.disabled{
  background-color: #FFF9F6;
  color: #000;
  /* border: 1px solid #000; */
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

/* Popup Content */
.popup-content {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
  max-width: 400px;
  position: relative;
}

/* Error Box Styling */
/* .error-box {
  border: 2px solid red;
} */

/* Close Button */
.close-popup-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  /* position: absolute; */
  top: 10px;
  right: 10px;
}

/* .close-popup-btn:hover {
  background: darkred;
} */

.close-popup-btn:focus {
  outline: none;
}

/* Popup Message Text */
/* .popup-content p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: red;
} */
.popup-content-error-box {
  background: white;
  padding: 20px 35px 20px 35px;
  border-radius: 10px;
  width: 40%;
  text-align: center;
  position: relative;
}
@media screen and (max-width : 600px) {
  .popup-content-error-box{
    width: 80%;
  }
}